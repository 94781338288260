<template>
    <body>
        <!-- preloader -->
        <Loader> </Loader>
        <!-- preloader -->

        <div class="frame">
            <div class="site-wrapper overflow-hidden">

                <!-- Header Section -->
                <Front-Header> </Front-Header>


                <!-- Main End-->
                <main class="business-page">
                    <section class="banner">
                        <div class="container">
                            <div v-if="!user.loggedIn" class="row">
                                <div class="col-md-6">
                                    <h2 class="title">
                                        New introductions for your Affiliate business, more value
                                    </h2>
                                    <h6 class="disc">
                                        Join Intro, the first of its kind affiliate platform.
                                    </h6>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-wrapper">
                                        <h3 class="affiliate-sign-up">Affiliate Sign-up</h3>
                                        <validation-observer ref="simpleRules">
                                            <form v-on:submit.prevent="validationForm">
                                                <div class="input-group">
                                                    <validation-provider name="First Name" rules="required|max:20"
                                                        #default="{ errors }">
                                                        <input type="text" name="firstname" v-model.trim="firstname"
                                                            :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" id=""
                                                            placeholder="First Name" />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>

                                                    <validation-provider name="Last Name" rules="required|max:20"
                                                        #default="{ errors }">
                                                        <input type="text" name="lastname" v-model.trim="lastname" id=""
                                                            placeholder="Last Name" />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </div>

                                                <validation-provider name="Email" rules="required|max:50|email|unique"
                                                    #default="{ errors }">
                                                    <input placeholder="Email" type="email" class="input"
                                                        v-model.trim="email"
                                                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>

                                                <validation-provider name="Password" rules="required|password|min:5|max:20"
                                                    #default="{ errors }">
                                                    <input placeholder="Password" type="password" v-model.trim="password"
                                                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'"
                                                        class="form-control form-control-lg">
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>

                                                <validation-provider name="Phone Number" rules="required|max:20"
                                                    #default="{ errors }">
                                                    <input type="text" name="phone" v-model.trim="phone"
                                                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" id=""
                                                        placeholder="Contact Number" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>

                                                <validation-provider name="City" rules="required|max:50"
                                                    #default="{ errors }">
                                                    <input type="text" name="city" v-model.trim="city"
                                                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" id=""
                                                        placeholder="City" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>

                                                <validation-provider name="Country Name" rules="required"
                                                    #default="{ errors }">
                                                    <select id="country" name="country" v-model="country"
                                                        aria-required="true" aria-invalid="false" class="input_field">
                                                        <option value="">Choose Country</option>
                                                        <option v-for="(option) in optionsCountry" v-bind:key="option.name"
                                                            v-bind:value="option.id">
                                                            {{ option.name }}
                                                        </option>
                                                    </select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>

                                                <input type="submit" value="SUBMIT" />

                                                <div class="tacbox">
                                                    <label class="lable-checkbox"> Accept <a
                                                            href="/affiliate/instructions">Terms andConditions</a> For Start
                                                        Affiliate Marketing.</label>
                                                    <input class="form-check-input flexChecked" type="checkbox" id=""
                                                        v-model="checkedValue" name="is_reuseable" value="1"
                                                        unchecked-value="0">
                                                </div>
                                            </form>
                                        </validation-observer>

                                    </div>
                                </div>
                            </div>
                            <div v-else-if="this.checkAffiliateUser == 0" class="row">
                                <div class="col-md-6">
                                    <h2 class="title">
                                        New introductions for your Affiliate business, more value
                                    </h2>
                                    <h6 class="disc">
                                        Join Intro, the first of its kind affiliate platform.
                                    </h6>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-wrapper">
                                        <h3 class="affiliate-sign-up">Update Affiliate Details</h3>
                                        <validation-observer ref="simpleRules">
                                            <form v-on:submit.prevent="validationFormUpdate">
                                                <validation-provider name="Phone Number" rules="required|max:20"
                                                    #default="{ errors }">
                                                    <input type="text" name="phone" v-model.trim="phone"
                                                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" id=""
                                                        placeholder="Contact Number" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>

                                                <validation-provider name="City" rules="required|max:50"
                                                    #default="{ errors }">
                                                    <input type="text" name="city" v-model.trim="city"
                                                        :class="errors.length > 0 ? 'is-invalid' : 'is-valid'" id=""
                                                        placeholder="City" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>

                                                <validation-provider name="Country Name" rules="required"
                                                    #default="{ errors }">
                                                    <select id="country" name="country" v-model="country"
                                                        aria-required="true" aria-invalid="false" class="input_field">
                                                        <option value="">Choose Country</option>
                                                        <option v-for="(option) in optionsCountry" v-bind:key="option.name"
                                                            v-bind:value="option.id">
                                                            {{ option.name }}
                                                        </option>
                                                    </select>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>

                                                <input type="submit" value="UPDATE" />

                                                <div class="tacbox">
                                                    <label class="lable-checkbox"> Accept <a
                                                            href="/affiliate/instructions">Terms andConditions</a> For Start
                                                        Affiliate Marketing.</label>
                                                    <input class="form-check-input flexChecked" type="checkbox" id=""
                                                        v-model="checkedValue" name="is_reuseable" value="1"
                                                        unchecked-value="0">
                                                </div>
                                            </form>
                                        </validation-observer>

                                    </div>
                                </div>
                            </div>
                            <div v-else class="row">
                                <div class="col-md-6">
                                    <h2 class="title">
                                        New introductions for your Affiliate business, more value
                                    </h2>
                                    <h6 class="disc">
                                        Join Intro, the first of its kind affiliate platform.
                                    </h6>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-wrapper">
                                        <h3 class="affiliate-sign-up">Affiliate Landing Page</h3>
                                        <router-link :to="{ name: 'affiliateProfile' }"
                                            class="nav-link next-to-affiliate">Next</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="being-partner">
                        <div class="container">
                            <h2 class="sectionTitle">Benefits of being a Intro partner</h2>
                            <div class="row">
                                <div class="col-md-3">
                                    <img src="../../assets/front/image/partner-1.png" alt="" />
                                    <h4>Increased Traffic</h4>
                                </div>
                                <div class="col-md-3">
                                    <img src="../../assets/front/image/partner-2.png" alt="" />
                                    <h4>Brand Awareness</h4>
                                </div>
                                <div class="col-md-3">
                                    <img src="../../assets/front/image/parnter-3.png" alt="" />
                                    <h4>Specialized Marketing</h4>
                                </div>
                                <div class="col-md-3">
                                    <img src="../../assets/front/image/partner-4.png" alt="" />
                                    <h4>Linked Promotion</h4>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="how_it_works_new">
                        <div class="container">
                            <h2 class="sectionTitle">
                                Introductions reshape communities and transform lives
                            </h2>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="Card-wrapper">
                                        <img src="../../assets/front/image/laptop.png" alt="" />
                                        <h4>Be Seen</h4>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="Card-wrapper">
                                        <img src="../../assets/front/image/speech.png" alt="" />
                                        <h4>Be Heard</h4>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="Card-wrapper">
                                        <img src="../../assets/front/image/introduction.png" alt="" />
                                        <h4>Be Introduced</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="com-md-12 ready-wrapper">
                                    <div class="wrapper">
                                        <h3>Ready to Join Us?</h3>
                                        <a href="#" class="btn">JOIN NOW</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <!-- Footer Section -->
                <Front-Footer> </Front-Footer>

            </div>
        </div>

    </body>
</template>
  
<script>
import { mapGetters } from "vuex";
import Header from './Front-Header';
import Footer from './Front-Footer';
import Loader from './Loader';
import VueMeta from 'vue-meta';
import Vue from 'vue';
Vue.use(VueMeta)
import { db } from '@/main';
import axios from 'axios';
import firebase from "firebase";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//Vee-Validate 
import { ValidationProvider, ValidationObserver, validate } from 'vee-validate';
import {
    required, email, confirmed, password, image, size
} from '@validations';

import store from '@/store'

export default {
    components: {
        'Front-Header': Header,
        'Front-Footer': Footer,
        'Loader': Loader,
        ValidationProvider,
        ValidationObserver,
        validate,
        required,
        email,
        confirmed,
        password,
        image,
        size,
    },
    data() {
        return {
            pages: {},
            address: {},
            users: {},
            sociallinks: {},
            addresss: '',
            business_name: '',
            emails: '',
            phones: '',
            subject: '',
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            city: '',
            phone: '',
            emailm: '',
            emailname: '',
            message: '',
            meta_title: '',
            meta_description: '',
            meta_keywords: '',
            optionsCountry: [],
            country: '',
            checkedValue: '',
            checkAffiliateUser: ''
        }
    },

    metaInfo() {
        return {
            title: "Affiliate",
            keywords: this.meta_keywords,
            meta: [
                { name: 'description', content: this.meta_description },
            ]
        }
    },

    created: function () {
        this.getCountries();
        this.fetchPages();
        this.getUser();
        this.getItem();
    },

    computed: {
        ...mapGetters({
            // map `this.user` to `this.$store.getters.user`
            user: "user",
        }),
    },

    methods: {

        validationForm() {

            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.submit();
                }
            })
        },

        validationFormUpdate() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.update();
                }
            })
        },

        getItem() {

            var id = localStorage.getItem("userSessionId");
            // alert(id)
            db.collection('users').doc(id).get()
                .then((doc) => {
                    if (!doc.data().affiliateStatus || doc.data().affiliateStatus == 0) {
                        this.checkAffiliateUser = 0;
                        // alert(this.checkAffiliateUser)
                    } else {
                        this.checkAffiliateUser = 1;
                        // alert(this.checkAffiliateUser)
                    }
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

        },

        // submit: function () {

        //     this.$router.push("/thankyou/?id=z0FnmzSpFP4sEopHNz4w");
        //     console.log("Added Successfully!");
        //     this.showNotification('Success, Added Successfully!', 'AlertTriangleIcon', 'success');
        //     //var axios = require('axios');
        //     var FormData = require('form-data');
        //     var data = new FormData();
        //     data.append(' first_name', this.first_name);
        //     data.append(' city', this.city);
        //     data.append(' business_name', this.business_name);
        //     data.append('last_name', this.last_name);
        //     data.append('email', this.email);
        //     data.append('emailm', this.emailm);
        //     data.append('phone', this.phone);
        //     data.append('subject', this.subject);
        //     var config = {
        //         method: 'post',
        //         url: this.$VUE_APP_API_ENDPOINT + 'partner',
        //         headers: { 'Content-Type': 'multipart/form-data' },
        //         data: data
        //     };

        //     axios(config)
        //         .then(function (response) {

        //             if ((JSON.stringify(response.data))) {

        //             }
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });


        //     // POST request using axios with set headers

        // },


        getUser() {
            db.collection("email_management")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {

                        if (doc.id == "z0FnmzSpFP4sEopHNz4w") {
                            this.emailm = doc.data().email;
                            this.emailname = doc.data().Name;
                        }

                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

        },

        fetchPages() {
            window.scrollTo({ top: 0, behavior: 'smooth' });

            db.collection("pages").doc('W3sk77Lpp6GYAS2RXM6F')
                .get()
                .then((doc) => {
                    console.log('hhhh88');
                    console.log(doc.id, " => ", doc.data().description);
                    this.pages = doc.data();
                    console.log(doc.data().description.split("|"));
                    this.addresss = doc.data().description.split("|")[0].split(">")[1];
                    this.emails = doc.data().description.split("|")[1];
                    this.phones = doc.data().description.split("|")[2].split("<")[0];

                    this.meta_title = doc.data().meta_title;
                    this.meta_description = doc.data().meta_description;
                    this.meta_keywords = doc.data.meta_keywords;
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

            db.collection("sociallinks").doc('ot3idgvr902v0m6ynLEz')
                .get()
                .then((doc) => {
                    //console.log('hhhh55');
                    //console.log(doc.id, " => ", doc.data());
                    this.sociallinks = doc.data();
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

            db.collection("address").doc('UukY8WLJp0Bhn12B4mJB')
                .get()
                .then((doc) => {
                    console.log('hhhh55');
                    console.log(doc.id, " => ", doc.data());
                    this.address = doc.data();
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

        },

        getCountries() {
            this.optionsCountry = [];
            db.collection("countries")
                .orderBy("country", "asc")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        // console.log(doc.data())
                        this.optionsCountry.push({
                            id: doc.id,
                            name: doc.data().country,
                            slug: doc.data().slug,
                        });
                    });
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        submit: function () {

            if (this.checkedValue == 0) {
                alert("Please accept terms & conditions for affiliate");
            } else {
                // localStorage.clear();
                //alert('Registered Successfully');
                store.dispatch('app/commitActivateLoader');

                firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
                    .then(data => {

                        data.user.updateProfile({
                            displayName: this.firstname
                        })
                            .then(() => {
                                var date = new Date().toLocaleString();//.toISOString().slice(0, 10);

                                db.collection("users")
                                    .add({
                                        user_id: data.user.uid,
                                        firstname: this.firstname,
                                        lastname: this.lastname,
                                        email: this.email,
                                        password: this.password,
                                        image: "",
                                        phone: this.phone,
                                        status: 2,
                                        country: this.country,
                                        nationality: this.country,
                                        affiliateStatus: 1,
                                        forgot_otp: '',
                                        otp_update: '',
                                        image: '',
                                        created: date,
                                        modified: date,
                                    })

                                    .then((docRef) => {
                                        localStorage.setItem("Ustatus", 2);
                                        let userData =
                                        {
                                            id: docRef.id,
                                            fullName: this.firstname,
                                            username: this.firstname + '' + this.lastname,
                                            avatar: "",
                                            email: this.email,
                                            ability:
                                                [{
                                                    action: "manage",
                                                    subject: "all"
                                                }],
                                            "extras": { "eCommerceCartItemsCount": 5 }
                                        }
                                        localStorage.setItem('userData', JSON.stringify(userData))
                                        this.$ability.update(userData.ability)
                                        localStorage.setItem("Ustatus", 2);
                                        console.log("Added Successfully!");
                                        localStorage.setItem("userSessionId", docRef.id);
                                        localStorage.setItem("userFirstname", this.firstname);
                                        // this.$router.replace({ name: "affiliateProfile" });
                                        this.showNotification('Affiliate Profile Created Successfully!', 'UserIcon', 'success');
                                        const url = this.$VUE_APP_API_ENDPOINT + "affiliateRegistrationThankyou";

                                        const data1 = {
                                            email: this.email,
                                            name: this.firstname,

                                        };

                                        const options = {
                                            method: 'POST',
                                            headers: { 'content-type': 'application/json' },
                                            data: JSON.stringify(data1),
                                            url: url
                                        };

                                        axios(options).then(
                                            (response) => {
                                                console.warn(Object.keys(response));
                                                if (response != "") {
                                                    this.$router.replace({ name: "affiliateProfile" });
                                                    store.dispatch('app/commitDeactivateLoader');
                                                }
                                            }, () => {
                                                this.isLoading = false;
                                            });
                                    })
                                    .catch((error) => {
                                        console.error("Error writing document: ", error);
                                        store.dispatch('app/commitDeactivateLoader');
                                        this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                                    });
                                var id = localStorage.getItem("userSessionId");

                            });
                    }).catch(err => {
                        console.log(err.message);
                        store.dispatch('app/commitDeactivateLoader');
                        this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                        this.error = err.message;
                    });
            }
        },

        update: function () {

            if (this.checkedValue == 0) {
                alert("Please accept terms & conditions for affiliate");
            } else {
                var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
                var id = localStorage.getItem("userSessionId");
                store.dispatch('app/commitActivateLoader');
                db.collection("users")
                    .doc(id)
                    .update({
                        phone: this.phone,
                        city: this.city,
                        country: this.country,
                        affiliateStatus: 1,
                        created: date,
                        modified: date,
                    })
                    .then(() => {
                        console.log("Updated Successfully!");
                        // this.$router.replace({ name: "affiliateProfile" });
                        this.showNotification('Affiliate Profile Updated Successfully!', 'UserIcon', 'success');
                        db.collection("users").doc(id)
                            .get()
                            .then((docs) => {
                                // console.log(docs.data())
                                const url = this.$VUE_APP_API_ENDPOINT + "affiliateUpdateThankyou";

                                const data1 = {
                                    email: docs.data().email,
                                    name: docs.data().firstname,
                                };

                                const options = {
                                    method: 'POST',
                                    headers: { 'content-type': 'application/json' },
                                    data: JSON.stringify(data1),
                                    url: url
                                };

                                axios(options).then(
                                    (response) => {
                                        console.warn(Object.keys(response));
                                        if (response != "") {
                                            this.$router.replace({ name: "affiliateProfile" });
                                            store.dispatch('app/commitDeactivateLoader');
                                        }
                                    }, () => {
                                        this.isLoading = false;
                                    });
                            })
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                        store.dispatch('app/commitDeactivateLoader');
                        this.showNotification('Error! Request Failed!', 'UserIcon', 'danger');
                    });
            }
        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    }
}

</script>
  
<style>
.contact_wrapper .contact_msg .form_fild .input_group select {
    width: 85%;
    border: none;
    border-bottom: 2px solid #e9e9e9;
    padding: 8px;
    padding-left: 0;
    font-size: 18px;

}

.contact_wrapper .contact_msg .form_fild .input_group .input.input_textarea {
    min-width: 85%;
    max-width: 85%;
}

.contact_wrapper .contact_msg .form_fild .input_group select:focus,
.contact_wrapper .contact_msg .form_fild .input_group select:focus-visible {
    outline: none;
    box-shadow: none;
}

@media (max-width:1024px) {
    .contact_wrapper .contact_msg .form_fild .input_group .input.input_textarea {
        min-width: 100%;
        max-width: 100%;
    }

    .contact_wrapper .contact_msg .form_fild .input_group select {
        width: 100%;
    }
}

.business-page .banner {
    background-image: url(../../assets/front/image/business.jpg);
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 22vh 0 9vh;
}

.business-page .banner::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    opacity: 0.6;
}

.business-page .banner .container {
    position: relative;
    z-index: 2;
}

.business-page .banner .container .row {
    align-items: center;
}

.business-page .banner .title {
    font-size: 3vw;
    line-height: 1.2;
    margin-bottom: 10px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 35px;
}

.business-page .banner .disc {
    font-size: 1.7708333333333333vw;
    margin: 0;
    color: #fff;
    font-weight: 200;
}

.business-page .banner .form-wrapper h3 {
    font-size: 25px;
}

.business-page .banner .form-wrapper h6 {
    padding-bottom: 30px;
    font-weight: 100;
}

.business-page .banner .form-wrapper {
    box-shadow: rgb(0 0 0 / 50%) 0px 2px 10px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 10% 10% 8%;
    max-width: 500px;
}

.business-page .banner .form-wrapper input,
.business-page .banner .form-wrapper select {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    line-height: 40px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #dddbda;
    border-image: initial;
    padding: 0 16px;
    margin-bottom: 15px;
}

.business-page .banner .form-wrapper input:focus,
.business-page .banner .form-wrapper input:focus-visible,
.business-page .banner .form-wrapper select:focus-visible,
.business-page .banner .form-wrapper select:focus {
    outline-color: #000;
}

.business-page .banner .form-wrapper select {
    padding: 11px 16px;
    color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.business-page .banner .form-wrapper input[type="submit"] {
    background: #03e1bc;
    border: none;
    color: #fff;
    font-weight: 600;
    padding: 5px;
}

.business-page .being-partner img {
    max-width: 70%;
    margin-bottom: 20px;
    height: 150px;
}

.business-page .being-partner {
    padding: 10vh 0;
}

.business-page .being-partner .row>div {
    text-align: center;
}

.business-page .being-partner .sectionTitle {
    font-size: 2vw;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    margin-bottom: 5vh;
}

.business-page .being-partner .row>div h4 {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
}

.business-page .how_it_works_new {
    padding: 10vh 0;
    background-image: url(../../assets/front/image/new-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.business-page .how_it_works_new .sectionTitle {
    font-size: 2vw;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 5vh;
}

.business-page .how_it_works_new img {
    max-width: 70%;
    margin-bottom: 10px;
    height: 100px;
}

.business-page .how_it_works_new .Card-wrapper {
    background: #fff;
    text-align: center;
    padding: 3rem;
    border-radius: 10px;
}

.business-page .how_it_works_new .wrapper {
    display: flex;
    justify-content: center;
    padding-top: 5vh;
    color: #fff;
    align-items: center;
}

.business-page .how_it_works_new .wrapper a.btn {
    background: #03e1bc;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
}

.business-page .how_it_works_new .wrapper h3 {
    margin-bottom: 0;
    margin-right: 20px;
    color: #fff;
}

.business-page .how_it_works_new .ready-wrapper {
    display: block;
    width: 100%;
}

@media (max-width:1024px) {
    .business-page .banner {
        min-height: 50vh;
        padding: 11vh 0 5vh;
    }
}

@media (max-width: 768px) {
    .business-page .banner {
        min-height: 80vh;
        padding: 20vh 0 5vh;
    }

    .business-page .being-partner .row>div {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 60px;
    }

    .business-page .how_it_works_new .Card-wrapper {
        padding: 1rem;
        min-height: 27vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 767px) {
    .business-page .banner {
        padding: 160px 0 5vh;
    }

    .business-page .banner .title {
        font-size: 18px;
        margin-bottom: 10px;
        text-align: center;
    }

    .business-page .banner .disc {
        font-size: 14px;
        margin-bottom: 25px;
        font-weight: 300;
        text-align: center;
    }

    .business-page .being-partner .row>div {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .business-page .being-partner .sectionTitle {
        font-size: 25px;
    }

    .business-page .being-partner {
        padding: 10vh 0 1vh;
    }

    .business-page .how_it_works_new .sectionTitle {
        font-size: 18px;
        max-width: 100%;
    }

    .business-page .how_it_works_new .Card-wrapper {
        margin-bottom: 20px;
    }

    .business-page .how_it_works_new .wrapper {
        padding-top: 3vh;
        flex-direction: column;
    }

    .business-page .input-group input[type="text"] {
        width: 100% !important;
    }

    .business-page .how_it_works_new .wrapper a.btn {
        display: block;
        width: 60%;
        margin-top: 20px;
    }

    .business-page .banner .form-wrapper h3 {
        font-size: 18px;
    }

    .business-page .banner .form-wrapper h6 {
        font-size: 14px;
        font-weight: 300;
    }
}

.input-group span {
    width: 100%;
}

.affiliate-sign-up {
    white-space: nowrap;
    font-family: Cinzel;
    font-weight: 700;
    font-size: 48px;
    line-height: 90px;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;
}

.tacbox {
    display: flex;
    padding: 2em;
    margin: 0em;
    border: 3px solid #ddd;
    background-color: #eee;
    /* max-width: 800px; */
}

.flexChecked {
    width: auto !important;
}

.lable-checkbox {
    position: relative;
    top: 2px;
}

.next-to-affiliate {
    background: #03e1bc;
    border: none;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}
</style>
  
  